import React, { memo, useState } from 'react';
import mova from 'mova';
import { userThunks } from 'state/ducks/user';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import store from '@state/store';
import Box from '@mui/material/Box';
import Button from '@components/Button/Button';
import FormikCheckbox from '@form/FormikCheckbox';
import SocialLoginButton from './components/SocialLoginButton';
import Divider from './components/Divider';
import Typography from '@mui/material/Typography';
import Icon from '@components/Icon/Icon';
import RouterLink from '@components/RouterLink/RouterLink';
import history from 'history.js';
import config from 'config';
import { useSelector } from 'react-redux';
import { userActions, userSelectors } from '@state/ducks/user';
import { Redirect } from 'react-router-dom';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import LanguageSelector from "../../components/Sidebar/components/LanguageSelector";
import PhoneField from 'views/components/PhoneField/PhoneField';
import phone from 'phone';

const t = mova.ns('auth.Login');

const schema = Yup.object().shape({
  phone: Yup.string()
    .required(() => t('errors.required'))
    .test(
      'isValidPhoneLength',
      () => t('errors.phone'),
      (value) => {
        const result = phone(value);
        return result.isValid;
      }),
  password: Yup.string()
    .required(() => t('errors.required')),
  rememberMe: Yup.boolean(),
  agreeLegal: Yup.boolean().required(() => t('errors.required')).oneOf([true], () => t('errors.agreeLegal')),
});

const Login = ({ isValid, dirty }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleOAuthFacebook = () => {
    window.location = `${config.apiRoot}/auth/admin/facebook`;
  };

  // const handleOAuthGoogle = () => {
  //   window.location = `${config.apiRoot}/auth/admin/google`;
  // };

  const user = useSelector(userSelectors.getCurrentUser());
  if (user) {
    return <Redirect to='/' />;
  }

  return (
    <Form>
      <Typography mt={10} mb={3} variant='h0' component='h1' align='center'>
        {t('title')}
      </Typography>
      {/*<SocialLoginButton sx={{ my: 1 }} fullWidth provider='google' onClick={handleOAuthGoogle} />*/}
      <SocialLoginButton sx={{ my: 1 }} fullWidth provider='facebook' onClick={handleOAuthFacebook} />
      <Divider />
      <Box sx={{ mb: '1rem' }}>
        <Field name='phone' component={PhoneField} />
      </Box>
      <FormikTextInput
        sx={{ my: 1 }}
        fullWidth
        name='password'
        placeholder={t('fields.password')}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton edge='end' onClick={() => setShowPassword(!showPassword)}>
                <Icon type={showPassword ? 'eyeOff' : 'eye'} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Box my={2} display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <FormikCheckbox name='rememberMe' label={t('rememberMe')} />
        <Typography variant='body2' align='right'>
          <RouterLink to='/forgot'>{t('forgotPassword')}</RouterLink>
        </Typography>
      </Box>
      <Box my={2} display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <FormikCheckbox
          name='agreeLegal'
          label={(
            <Box component='span'>
              <Box component='span'>{t('agreeLegal')}</Box>&nbsp;
              <a target='_blank' href='https://restaurant.reservble.com/partner_agreement/' rel='noopener noreferrer'>
                {t('legal')}
              </a>
            </Box>
          )}
        />
      </Box>
      <Button fullWidth
        disabled={!isValid || !dirty}
        type='submit'
        variant='contained'
        color='primary'
        endIcon={<Icon type='arrow' />}>
        {t('submit')}
      </Button>
      {/*<Typography mt={2} variant='body2' align='center' color='typography.info'>*/}
      {/*{t('dontHaveAccount')}&nbsp;<RouterLink to='/register'>{t('createAccount')}</RouterLink>*/}
      {/*</Typography>*/}
      <LanguageSelector mt={4} display='flex' alignItems='center' justifyContent='center' black />
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      phone: '',
      password: '',
      rememberMe: false,
      agreeLegal: false,
    }),
    validationSchema: schema,
    handleSubmit: (credentials, helpers) => {
      store.dispatch(
        userThunks.loginUser({
          credentials,
          callback: async response => {
            await store.dispatch(userActions.setUserData(response));
            helpers.setSubmitting(false);
            history.push('/');
          },
        }),
      );
    },
  })(Login),
);
