import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/Modal/Modal';
import mova from 'mova';
import { Form, withFormik, Field } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import ModalActions from '@components/Modal/ModalActions';
import { clientThunks } from 'state/ducks/client';
import { toastrActions } from 'state/ducks/toastr';
import store from '@state/store';
import FormikSelect from '@form/FormikSelect';
import { reservationActions } from "state/ducks/reservation";
import PhoneField from 'views/components/PhoneField/PhoneField';
import phone from 'phone';
import { locales } from "../../../../utils/i18nUtils";

const t = mova.ns('components.Clients');

const schema = Yup.object().shape({
  firstName: Yup.string().required(() => t('errors.required')),
  lastName: Yup.string(),
  phone: Yup.string()
    .required(() => t('errors.required'))
    .test(
      'isValidPhoneLength',
      () => t('errors.phone'),
      (value) => {
         const result = phone(value);
      return result.isValid;
    }),
  email: Yup.string().email(() => t('errors.email')),
  lang: Yup.string(),
});

const AddClientModal = ({ open, onClose, submitForm, client }) => {
  const langItems = locales.map(lang => ({
    value: lang,
    label: t(`lang.${lang}`),
  }));

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={client ? t('editingClient') : t('addNewClient')}
      actions={<ModalActions onClose={onClose} submitForm={submitForm} />}
    >
      <Form>
        <FormikTextInput sx={{ my: 1 }} fullWidth name='firstName' placeholder={t('fields.firstName')} />
        <FormikTextInput sx={{ my: 1 }} fullWidth name='lastName' placeholder={t('fields.lastName')} />
        <Field name='phone' component={PhoneField} />
        <FormikTextInput sx={{ my: 1 }} fullWidth name='email' placeholder={t('fields.email')} />
        <FormikSelect sx={{ my: 1 }} fullWidth name='lang' label={t('fields.lang')} items={langItems} />
      </Form>
    </Modal>
  );
};

AddClientModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  placeId: PropTypes.number.isRequired,
};

export default memo(
  withFormik({
    mapPropsToValues: ({ client }) => ({
      firstName: client?.firstName || '',
      lastName: client?.lastName || '',
      phone: client?.phone || '',
      email: client?.email || '',
      lang: client?.lang || '',
    }),
    validationSchema: schema,
    handleSubmit: async (data, helpers) => {
      if (helpers.props.client?.id) {
        await store.dispatch(
          clientThunks.updateClient({ ...data, email: data.email || null, id: helpers.props.client.id }),
        );
        helpers.props.reloadClients();
        store.dispatch(reservationActions.incrementReservationsKey());
        helpers.props.onClose();
      } else {
        const result = await store.dispatch(
          clientThunks.addClient({ ...data, email: data.email || null, placeId: helpers.props.placeId }),
        );
        const isExist =
          `${result?.meta?.arg?.firstName} ${result?.meta?.arg?.lastName}` !== result?.payload?.displayedName;
        store.dispatch(
          toastrActions.showToastr({
            variant: isExist ? 'error' : 'success',
            message: isExist
              ? t('messages.exist', { displayedName: result?.payload?.displayedName })
              : t('messages.added'),
          }),
        );
        if (!isExist) {
          helpers.props.reloadClients();
        }
        store.dispatch(reservationActions.incrementReservationsKey());
        helpers.props.onClose();
      }
    },
    enableReinitialize: true,
  })(AddClientModal),
);
