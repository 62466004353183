export default {
  base: {
    cancel: 'Cancelar',
    save: 'Guardar',
    confirm: 'Confirmar',
    close: 'Cerrar',
    search: 'Buscar',
    login: 'Iniciar sesión',
    register: 'Registrarse',
    submit: 'Enviar',
    edit: 'Editar',
    delete: 'Eliminar',
    city: 'Ciudad',
    seeAll: 'Ver todos',
    length: 'Longitud',
    width: 'Ancho',
    height: 'Altura',
    unknownUser: 'Invitado',
    sendAgain: 'Enviar de nuevo',
  },
  lang: {
    ua: 'Ucraniano',
    ru: 'Ruso',
    en: 'Inglés',
    es: 'Español',
  },
  currencies: {
    uah: 'UAH',
  },
  types: {
    user: {
      phone: 'Teléfono',
      email: 'Correo electrónico',
      password: 'Contraseña',
      oldPassword: 'Contraseña antigua',
      newPassword: 'Nueva contraseña',
      confirmPassword: 'Confirmar contraseña',
      firstName: 'Nombre',
      lastName: 'Apellido',
      confirm: '<base.confirm>',
      avgBillAmount: 'Factura promedio',
      restaurant: 'Restaurante',
      city: '<base.city>',
      lang: 'Idioma de comunicación',
    },
    admin: {
      '@extends': 'types.user',
      lastLogin: 'Última vez en línea',
      role: 'Rol',
      roles: {
        admin: 'Administrador',
        analyst: 'Analista',
        owner: 'Propietario',
      },
    },
    place: {
      name: 'Nombre',
      description: 'Descripción completa',
      address: 'Dirección',
      latitude: 'Latitud',
      longitude: 'Longitud',
      active: 'Activo',
      link: 'Enlace',
      cusines: 'Cocinas del lugar',
      moods: 'Ambiente del lugar',
      payments: 'Tipo de pagos',
      city: '<base.city>',
      web: 'Sitio web',
      phone: 'Teléfono',
      time: 'Hora',
      contactPhone: 'Teléfono de contacto',
      averageBill: 'Cuenta promedio',
    },
    placeOpenHours: {
      sun: 'Domingo',
      mon: 'Lunes',
      tue: 'Martes',
      wed: 'Miércoles',
      thu: 'Jueves',
      fri: 'Viernes',
      sat: 'Sábado',
    },
    placeHall: {
      name: 'Nombre',
      active: 'Activo',
      length: '<base.length> (m)',
      width: '<base.width> (m)',
      order: 'Orden',
    },
    placeSpot: {
      type: 'Tipo',
      label: 'Nombre',
      seats: 'N° de asientos',
      minSeats: 'N° mínimo de asientos',
      length: '<base.length> (m)',
      width: '<base.width> (m)',
      priority: 'Prioridad',
      angle: 'Ángulo',
      oneSeat: 'Soltero',
      twoSeat: 'Doble',
      border: 'Borde',
      depositCategory: 'Categoría de depósito',
      borderType: {
        solid: 'Sólido',
        dashed: 'Punteado',
        dotted: 'Puntos',
      },
    },
    cities: {
      kyiv: 'Kiev',
      odessa: 'Odesa',
    },
    reservation: {
      accepted: 'Aceptadas',
      requested: 'Nuevas',
      success: 'Exitosas',
      rejected: 'Rechazadas',
      cancelled: 'Canceladas',
      skipped: 'Omitidas',
      finished: 'Finalizadas',
      waitlist: 'Lista de espera',
      inProgress: 'En progreso',
      totalReserves: 'Total de reservas',
      successReservations: 'Reservas exitosas',
      failedReservations: 'Reservas fallidas',
      comment: 'Comentario',
      hall: 'Salón',
      spot: 'Mesa',
      spots: 'Mesas',
      from: 'Inicio',
      to: 'Fin',
      seats: 'Cantidad de personas',
      event: 'Evento',
      events: {
        other: 'Otro',
        business: 'Reunión de negocios',
        date: 'Cita',
        family: 'Recreación familiar',
        friends: 'Encuentro con amigos',
        birthday: 'Cumpleaños',
        football: 'Fútbol',
      },
      keepTable: 'No reubicar',
      manager: 'Administrador',
      waitForTomorrow: 'Transferir al día siguiente',
    },
    dish: {
      name: 'Nombre del plato',
      categories: 'Categorías',
      photo: 'Foto',
      description: 'Descripción',
      weight: 'Peso',
      price: 'Precio, UAH',
      active: 'Activo',
      vegetarian: 'Vegetariano',
      hot: 'Picante',
      top: 'Top',
    },
    dishCategory: {
      name: 'Nombre de la categoría',
      parent: 'Categoría principal',
      order: 'Orden',
    },
    legal: {
      privacy: 'Política de privacidad',
      privacyWhat: 'Política de privacidad',
      terms: 'Términos de uso',
      termsWhat: 'Términos de uso',
    },
    channel: {
      name: 'Nombre',
    },
    certificate: {
      active: 'Activo',
      name: 'Nombre',
      category: 'Categoría',
      categoryCertificate: 'Certificado',
      categorySubscription: 'Suscripción',
      price: 'Precio',
      description: 'Descripción',
      photo: 'Foto',
      type: 'Tipo',
      typeVisits: 'Por número de visitas',
      typeAmount: 'Por cantidad',
      visits: 'Número de visitas',
      amount: 'Cantidad',
      expiration: 'Vigencia',
      expirationDate: 'Hasta una fecha específica',
      expirationDays: 'Días desde la compra',
      tillDate: 'Hasta qué fecha',
      validDays: 'Número de días',
      activation: 'Fecha de activación',
      activationPurchase: 'Desde la compra',
      activationVisit: 'Después de la primera visita',
      activationVisitMaxDays: 'Activar automáticamente en (días)',
      duration: 'Duración de la suscripción (días)',
      id: 'Número único',
      clientName: 'Nombre del cliente',
      clientLastName: 'Apellido del cliente',
      phone: 'Teléfono',
      status: 'Estado',
      purchased: 'Fecha de compra',
      comment: 'Comentario',
      statuses: {
        DRAFT: 'No pagado',
        INACTIVE: 'No activo',
        ACTIVE: 'Activo',
        USED: 'Usado',
        EXPIRED: 'Expirado',
      }
    },
  },
  auth: {
    Login: {
      fields: { '@extends': 'types.user' },
      forgotPassword: '¿Olvidaste la contraseña?',
      title: 'Iniciar sesión',
      errors: {
        '@any': '¡Ha ocurrido un error!',
        '@extends': 'errors.validation',
        wrongAuth: 'Teléfono o contraseña incorrectos',
        wrongRole: 'No tienes permiso para acceder a este servicio. Por favor, contacta al administrador',
        agreeLegal: 'Debes aceptar estos términos.',
      },
      submit: '<base.login>',
      dontHaveAccount: '¿No tienes una cuenta?',
      createAccount: 'Crear cuenta',
      rememberMe: 'Recuérdame',
      or: 'o',
      agreeLegal: 'Yo acepto las',
      legal: 'Reglas de interacción de la información con el Servicio Reservble',
    },
    Register: {
      fields: { '@extends': 'types.user' },
      legal: { '@extends': 'types.legal' },
      cities: { '@extends': 'types.cities' },
      title: 'Registro',
      errors: {
        '@any': '¡Ha ocurrido un error!',
        '@extends': 'errors.validation',
        confirm: 'Debes aceptar los términos y condiciones',
      },
      haveAccount: '¿Ya tienes una cuenta?',
      confirm: 'Acepto los',
      and: 'y los',
      submit: '<base.register>',
      login: '<base.login>',
    },
    Social: {
      signIn: 'Iniciar sesión con',
      providers: {
        google: 'Google',
        facebook: 'Facebook',
      },
    },
    Forgot: {
      fields: { '@extends': 'types.user' },
      errors: {
        '@any': '¡Ha ocurrido un error!',
        '@extends': 'errors.validation',
        userWithSpecifiedEmailNotFoundd: 'No se encontró una cuenta con ese correo electrónico',
      },
      login: '<base.login>',
      title: 'Recuperar contraseña',
      buttonSend: 'Enviar instrucciones por correo',
      goBackLogin: 'Volver al inicio de sesión',
    },
  },
  errors: {
    validation: {
      '@root': 'Formato incorrecto',
      '@any': '<.>',
      format: '<.>',
      required: 'Este campo es obligatorio',
      password: 'La contraseña ingresada es demasiado corta',
      email: 'Por favor, usa un formato de correo electrónico válido',
      phone: 'Usa un formato de teléfono válido: +34000111222',
      minLength: 'Longitud mínima <=symbols> caracteres',
      maxLength: 'Longitud máxima <=symbols> caracteres',
      number: 'Debe ser un número',
      minNumber: 'El valor mínimo es <=min>',
      maxNumber: 'El valor máximo es <=max>',
      validationError: 'El conjunto de datos de entrada no es válido en los campos: <=fields>',
      notUniq: 'El campo no es único',
      url: 'El enlace debe ser una URL válida',
      passwordsMatch: 'Las contraseñas no coinciden',
    },
    badRequest: 'Intenta de nuevo o contacta al administrador',
    socialUserAlreadyExist: 'El usuario ya está vinculado a otra cuenta',
    global: {
      somethingWrong: 'Algo salió mal',
      refresh: 'Refresca la página y si el problema persiste, contacta a nuestro equipo en nosenkocc@gmail.com',
    },
    menuHasDishes: 'No puede eliminar categorías que contengan platos',
    menuHasChild: 'Las categorías con subcategorías no se pueden eliminar',
  },
  components: {
    Select: {
      selectAll: 'Seleccionar todo',
    },
    Sidebar: {
      superadmin: 'Superadministrador',
      home: 'Inicio',
      reservations: 'Reservas',
      timeline: 'Cronograma',
      clients: 'Clientes',
      halls: 'Salones',
      channels: 'Canales de venta',
      menu: 'Menú',
      certificates: 'Certificados',
      reviews: 'Reseñas',
      statistics: 'Estadísticas',
      payment: 'Pago',
      incomingPayments: 'Aceptación de pagos',
    },
    DashboardMenu: {
      reservation: 'Reservas',
      channels: 'Canales de venta',
      top10: 'Top 10',
      reviews: 'Reseñas',
    },

    Reservations: {
      title: 'Reservas',
      hall: 'Salón',
      table: 'Mesa',
      accept: 'Confirmar',
      reject: 'Rechazar',
      rejectReasonTitle: 'Indique el motivo del rechazo',
      rejectReason: 'Motivo del rechazo',
      rejectNoPlacesShort: 'Insertar texto: No hay lugares disponibles',
      rejectNoPlaces: 'Ahora no hay lugares disponibles para el tiempo seleccionado',
      customDeclineMsg: 'Añadir mensaje personalizado',
      confirm: 'Rechazar',
      finish: 'Finalizar',
      finishingReservation: 'Finalización exitosa de la reserva',
      confirmFinishReservation: '¿Está seguro de que desea marcar esta reserva como completada con éxito?',
      billAmount: 'Monto de la factura',
      indicateBillAmount: 'Indique el monto de la factura',
      skip: 'Omitido',
      skippingReservation: 'Reserva omitida',
      confirmSkipReservation: '¿Está seguro de que desea marcar esta reserva como omitida por el cliente?',
      updateBillAmount: 'Editar monto de la factura',
      updatingBill: 'Editando monto de la factura',
      showHistory: 'Mostrar historial de cambios',
      clientCancelled: 'El cliente canceló la reserva',
      cancellingReservation: 'Cancelando la reserva',
      confirmCancelReservation:
        '¿Está seguro de que desea cancelar esta reserva en nombre del cliente? Esto solo debe hacerse si el cliente solicitó cancelar la reserva.',
      movingToInProgress: 'El cliente llegó al restaurante',
      backToAccepted: 'Volver a aceptadas',
      confirmBackToAccepted: '¿Está seguro de que desea volver a mover esta reserva a la lista aceptadas?',
      confirmMoveToInProgress: '¿Confirma que el cliente llegó al restaurante?',
      additionalConfirmation: 'Confirmar reserva',
      confirmAdditionalConfirmation: '¿Está seguro de que quiere confirmar esta reserva?',
      deleteFromWaitList: 'Eliminar de la lista de espera',
      confirmDeleteFromWaitList: '¿Está seguro de que quiere eliminar esta entrada de la lista de espera?',
      errors: { '@extends': 'errors.validation' },
      filter: { '@extends': 'types.reservation' },
      seeAll: '<base.seeAll>',
      acceptingReservation: 'Confirmando la reserva',
      confirmAcceptReservation: '¿Está seguro de que desea confirmar esta reserva?',
      today: 'Hoy',
      tomorrow: 'Mañana',
      people: 'pers.',
      callClient: 'Llamar al cliente',
      disableDay: 'Cerrar reservas',
      enableDay: 'Abrir reservas',
      disablingDay: 'Cerrando reservas',
      enablingDay: 'Abriendo reservas',
      showOnlyUnconfirmed: 'Mostrar solo no verificados',
      onlyThisHall: 'Reservas sólo en este salón',
      confirmDisableDay:
        '¿Está seguro de que desea cerrar la posibilidad de reservar para el día actual? Los clientes no podrán reservar una mesa para hoy hasta que desactive manualmente esta opción. Esta configuración se desactivará automáticamente mañana por la mañana.',
      confirmEnableDay:
        '¿Está seguro de que desea abrir la posibilidad de reservar para el día actual? Los clientes podrán volver a reservar una mesa para hoy.',
      dayClosedWarning:
        'La posibilidad de reserva ha sido desactivada para el día actual. Los nuevos clientes no podrán hacer reservas para hoy.',
      comment: 'Comentario: ',
      cancelReason: 'Motivo de cancelación: ',
      cancelReasons: {
        changeOfPlans: 'Cambios de planes',
        mistake: 'La reserva fue creada por error',
        late: 'No llego a tiempo',
        changeTime: 'Quiero elegir otro horario',
        adminCancelled: 'El administrador canceló la reserva a petición del cliente',
      },
      unknownUser: '<base.unknownUser>',
      indicateHallAndTable: 'Indique el salón y la mesa',
      edit: '<base.edit>',
      pickSpot: 'Elegir mesa',
      spotNotFound: 'No se encontró mesa disponible para el tiempo seleccionado',
      events: { '@extends': 'types.reservation.events' },
      minutes: 'm',
      hours: 'h',
      uah: '<currencies.uah>',
      viewMenu: 'Ver el menú',
    },
    Channels: {
      title: 'Canales de venta',
      noChannels: 'Aún no hay canales de venta',
      copyButtonLabel: 'copiar',
      promoLabel: 'Promoción',
      activeLabel: 'Activado',
      name: 'Nombre',
      successReservations: 'Exitosas',
      unfinishedReservations: 'Inconclusas',
      addChannel: 'Añadir nuevo canal de venta',
      update: 'Actualizar canal de venta',
      copiedSuccessfully: 'Enlace copiado al portapapeles',
      seeAll: '<base.seeAll>',
      addNewChannel: 'Añadir canal de venta',
      fields: { '@extends': 'types.place' },
      errors: { '@extends': 'errors.validation' },
      deletingChannel: 'Eliminando canal de venta',
      confirmDeleteChannel: '¿Está seguro de que quiere eliminar este canal de venta?',
      system: {
        placeReservations: {
          name: 'Administrador',
          description: 'Reservas creadas manualmente',
        },
        guestReservations: {
          name: 'Reservble Marketplace',
          description: 'Reservations',
        },
      },
    },
    ChannelStatistics: {
      '@extends': 'types.reservation',
      backToChannels: 'Volver a la lista',
      newUsers: 'Nuevos usuarios',
      chartTitle: 'Estadísticas del canal de venta',
    },
    Top10: {
      attendanceTitle: 'TOP-10 Asistencia',
      attendanceLabel: 'Asistencias',
      checkTitle: 'TOP-10 Facturas',
      checkLabel: 'Factura promedio',
      noData: 'Datos insuficientes para mostrar estadísticas',
    },
    Settings: {
      settings: 'Configuraciones',
      description: 'Descripción',
      characteristics: 'Características',
      reservations: 'Reservas',
      gallery: 'Galería',
      hours: 'Horarios de atención',
      contacts: 'Contactos',
      administration: 'Administración',
      halls: 'Salones',
      deposits: 'Depósitos',
      integrations: 'Integraciones',
      bookingWidget: 'Widget de reservas',
      promo: 'Promociones',
      certificates: 'Certificados',
      fields: { '@extends': 'types.place' },
      hourFields: { '@extends': 'types.placeOpenHours' },
      inactive: 'Inactivo',
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.settings' },
      cancel: '<base.cancel>',
      save: '<base.save>',
      contactForm: {
        fields: {
          type: 'Tipo',
          reference: 'Referencia',
          referencePlaceholder: 'Complete el campo',
        },
        errors: {
          '@any': '¡Ha ocurrido un error!',
          '@extends': 'errors.validation',
        },
        types: {
          EMAIL: 'Correo electrónico',
          PHONE: 'Teléfono',
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          WEB: 'Web',
        },
        submit: 'Agregar contacto',
        update: 'Actualizar contacto',
      },
      paidServices: {
        title: 'Servicios adicionales',
        active: 'Activo',
        label: 'El nombre del botón',
      },
    },
    Clients: {
      title: 'Clientes',
      visits: 'Número de visitas',
      cheque: 'Factura promedio',
      addClient: 'Agregar',
      addNewClient: 'Añadir nuevo cliente',
      editingClient: 'Editando cliente',
      cancel: '<base.cancel>',
      save: '<base.save>',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.clients' },
      sort: {
        date: 'Por fecha de agregado',
        cheque: 'Por factura promedio',
        visits: 'Por número de visitas',
      },
      lang: { '@extends': 'lang' },
    },
    Pagination: {
      of: 'de',
    },
    SearchInput: {
      search: '<base.search>',
    },
    Administration: {
      title: 'Administración',
      fields: { '@extends': 'types.admin' },
      addAdmin: 'Añadir administrador',
      addNewAdmin: 'Añadiendo administrador',
      editingAdmin: 'Editando administrador',
      cancel: '<base.cancel>',
      save: '<base.save>',
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.administrations' },
      deletingAdmin: 'Eliminando administrador',
      confirmDeleteAdmin: '¿Está seguro de que desea eliminar este administrador?',
    },
    Integrations: {
      telegramService: 'Telegram API',
      telegramKey: 'ID de usuario o grupo en Telegram',
      googleService: 'Google My Business',
      googleKey: 'ID del lugar',
      commentLanguage: 'Comments language',
      facebookService: 'Facebook Business Manager',
      facebookKey: 'Token de acceso de la página',
      syrveService: 'Syrve',
      syrveKey: 'Clave API',
      syrveOrganization: 'Organización',
      syrveTerminal: 'Terminal principal',
      syrveApiFailed: 'Clave API incorrecta',
      loadSyrveMenu: 'Descarga el menú',
      portmoneService: 'Portmone',
      posterService: 'Poster',
      syncPosterTables : 'Sync poster tables',
      synced: 'The tables are synchronized',
      notSynced: 'Tables are not synchronized',
      portmoneKey: 'Portmone Payee ID',
      posterClientId: 'Poser Client ID',
      connect: 'Conectar',
      reset: 'Restablecer',
      loadSyrveOrganisations: 'Cargar organización',
      loadSyrveTerminals: 'Cargar terminal',
      connected: 'Conectado',
      telegramConnectionFailed:
        'No se pudo enviar el mensaje al chat con este ID. Escriba a @reservble_bot un mensaje personal para recibir notificaciones individuales, o agregue el bot a un grupo para recibir mensajes en ese grupo. Luego, intente de nuevo.',
      googleApiFailed: 'ID de lugar incorrecto',
      enableBanquetPreOrder: 'Habilitar la sincronización del banquete',
      enablePaymentsOrder: 'Habilitar pagos por órdenes de mesa',
      enableSyncReservations: 'Habilitar sincronización de reserva',
      errors: { '@extends': 'errors.validation' },
      lang: { '@extends': 'lang' },
    },
    ReservationSettings: {
      title: 'Reservas',
      ourManagement: 'No consultar disponibilidad al reservar online',
      autoSeating: 'Confirmación automática de reservas',
      tgAdminNotification: 'Recordatorios de reservas omitidas',
      smsConfirmation: 'Enviar mensaje de texto de confirmación de reserva',
      smsFeedback: 'Enviar mensaje de texto solicitando una reseña después de una reserva exitosa',
      newReservationMessage: 'Enviar mensaje en Telegram después de crear una nueva reserva por el administrador',
      reservationCancelMessage: 'Enviar mensaje en Telegram al rechazar o cancelar una reserva',
      showHalls: 'Permitir a los clientes elegir un salón durante la reserva en línea',
      showSpots: 'Permitir a los clientes elegir una mesa durante la reserva en línea',
      spotsMandatory: 'Hacer obligatoria la selección de mesa',
      stepTimeSlots: 'Intervalo de tiempo entre franjas horarias de reserva',
      reservationDuration: 'Duración estándar de la reserva, en minutos',
      reservationAllDay: 'Hasta el final del día',
      lastTimeReservation1: 'Aceptar la última reserva',
      lastTimeReservation2: 'minutos antes del cierre',
      highlightReservations: 'Destacar reservas antes de su inicio',
      minutesBefore: 'Cuántos minutos antes',
      hideTableCapacity: 'No mostrar capacidad de la mesa',
      disableSameTimeReservation: 'No permitir crear reservas por el mismo tiempo',
      showAutomaticReservationButton: 'Mostrar botón para elegir un asiento automáticamente',
      syrveDelay: 'Después de cerrar la cuenta en Syrve, marcar la mesa como disponible después de',
      depositsActive: 'Habilitar depósitos',
      depositsPaymentTime: 'Tiempo de pago del depósito, minutos',
      onlyDepositReservations: 'Permitir crear reservas solo con depósitos',
      menuActive: 'Habilitar menú',
      clientMenuActive: 'También mostrar menú en el cliente',
      certificatesActive: 'Mostrar certificados en el cliente',
      customDeclineMsg: 'Mensaje de reserva de rechazo personalizado',
      minutes: 'minutos',
      save: '<base.save>',
      success: '<actions.settings.saved>',
      title2: 'Información adicional para las reservas',
      customTagsTitle: 'Anulación de etiquetas',
      hourFields: { '@extends': 'types.placeOpenHours' },
      apply: 'Aplicar',
      message: {
        ruPlaceholder: 'Ruso',
        uaPlaceholder: 'Ucraniano',
        enPlaceholder: 'Inglés',
        ruDefault: 'Debido a la alta demanda, hay una limitación de 2 horas para la duración de la reserva',
        uaDefault: 'Debido a la alta demanda, hay una limitación de 2 horas para la duración de la reserva',
        enDefault: 'Due to high demand, there is a 2-hour limitation for the duration of the reservation',
      },
      highloadTitle: 'Opciones adicionales',
      adminSelection: 'Elija administrador al crear una reserva',
      showAllReservations: 'Permitir que todas las reservas de mesa se muestren a la vez',
      showReservationConfirmed: 'Confirmación adicional de reservas',
      showWaitList: 'Mostrar lista de espera',
      reservationMissed: 'Cancelar reservas automáticamente si el cliente no llega en',
      reservationMissedTime: 'minutos',
      showHallFilter: 'Permitir filtrar reservas por salas',
      maxGuests: 'Cantidad máxima de huéspedes por reserva',
      maxGuestsLabel: 'Ingrese la cantidad',
    },
    BookingWidget: {
      organisationTitle: 'Restaurantes de cadena',
      showAddress: 'Mostrar dirección en lugar del título',
      styleTitle: 'Configuración de estilo',
      backgroundColor: 'Color de fondo',
      borderColor: 'Color del borde',
      primaryColor: 'Color primario',
      primaryColorHover: 'Color primario al pasar el mouse',
      textColor: 'Color del texto',
      contrastTextColor: 'Color de contraste del texto',
      errorColor: 'Color de error',
      configTitle: 'Otras configuraciones',
      showSlots: 'Mostrar franjas horarias disponibles',
      showHalls: 'Mostrar salas para selección',
      translations: 'Elementos de texto',
      pageTitle: 'Título de la página',
      policyTitle: 'Título de la política',
      policyText: 'Texto principal de la política',
      policyItems: 'Elementos de política',
      finishTitle: 'Título al finalizar la reserva',
      finishMessage: 'Mensaje al finalizar la reserva',
      save: '<base.save>',
      lang: { '@extends': 'lang' },
      success: '<actions.settings.saved>',
    },
    Deposits: {
      addDeposit: 'Agregar categoría',
      addType: 'Agregar período',
      active: 'Activa',
      depositPlaceholder: 'Nombre de la categoría',
      typePlaceholder: 'Nombre del período',
      amountInUah: 'Ingrese el monto del depósito en el día determinado en',
      amountInUahPlaceholder: 'El monto en UAH',
      uah: '<currencies.uah>',
      days: { '@extends': 'types.placeOpenHours' },
      save: '<base.save>',
      success: '<actions.settings.saved>',
      errors: { '@extends': 'errors.validation' },
      minOneType: 'La categoría debe contener al menos un período',
      deletingDeposit: 'Eliminando categoría',
      confirmDeleteDeposit: '¿Está seguro de que desea eliminar esta categoría? Esta acción no se puede deshacer',
      deletingType: 'Eliminando período',
      confirmDeleteType: '¿Está seguro de que desea eliminar este período? Esta acción no se puede deshacer',
      disableQuantityLabel: 'No mostrar la selección de cantidad para esta categoría',
    },
    Promo: {
      title: 'Promociones',
      current: 'Promoción actual',
      save: '<base.save>',
      noPromo: 'Ninguna',
      percent: 'Porcentaje',
      errors: { '@extends': 'errors.validation' },
      success: '<actions.settings.saved>',
    },
    Halls: {
      title: 'Salones del establecimiento',
      addNewHall: 'Añadir salón',
      editHall: '<base.edit>',
      activeHall: 'Seleccionar salón',
      save: '<base.save>',
      errors: { '@extends': 'errors.validation' },
      spotsForm: {
        fields: { '@extends': 'types.placeSpot' },
        errors: {
          '@any': '¡Ha ocurrido un error!',
          '@extends': 'errors.validation',
        },
        duplicate: 'Duplicar',
        delete: 'Eliminar',
        submit: 'Añadir mesa',
        update: 'Actualizar mesa',
        backToHalls: 'Salones',
        types: { ELLIPSE: 'Redonda', RECT: 'Rectangular', LOUNGE: 'Tumbona' },
        ifZeroMinSeats: "Si es 0, se considerará el número del campo 'N° de asientos'",
        manualChairs: 'Colocación manual de sillas',
        top: 'Arriba',
        bottom: 'Abajo',
        left: 'Izquierda',
        right: 'Derecha',
        couch: 'Sofá',
        active: 'Activo',
      },
      hallForm: {
        addNewHall: 'Añadiendo nuevo salón',
        editHall: 'Editando salón',
        fields: { '@extends': 'types.placeHall' },
        cancel: '<base.cancel>',
        save: '<base.save>',
        errors: { '@extends': 'errors.validation' },
        pattern: 'Fondo del salón',
        patterns: {
          none: 'Ninguno',
          asphalt: 'Asfalto',
          stone: 'Piedra',
          tiles: 'Azulejos',
          wood: 'Madera',
        },
        bgCustom: 'Imagen personalizada',
      },
      reservationForm: {
        addingReservation: 'Creando reserva',
        editingReservation: 'Editando reserva',
        cancel: '<base.cancel>',
        save: '<base.save>',
        fields: {
          user: { '@extends': 'types.user' },
          reservation: { '@extends': 'types.reservation' },
        },
        errors: { '@extends': 'errors.validation' },
        pickSpot: 'Elegir mesa',
        spotNotFound: 'No se encontró mesa libre para el tiempo seleccionado',
        addToWaitList: 'Añadir a la lista de espera',
        sameTimeReservationDisabled: 'El administrador prohíbe crear reservas para el mismo tiempo',
        openMenu: 'Añadir platos',
        certificateId: 'ID del certificado',
        findCertificate: 'Buscar certificado',
        messages: { '@extends': 'actions.certificates' }
      },
      deletingSpot: 'Eliminando mesa',
      confirmDeleteSpot: '¿Está seguro de que desea eliminar esta mesa?',
      deletingHall: 'Eliminando salón',
      confirmDeleteHall:
        '¿Está seguro de que desea eliminar este salón? Todas las mesas en él también serán eliminadas',
      accept: 'Confirmar',
      assigningReservation: 'Asignando reserva',
      assignText: '¿Está seguro de que desea confirmar esta reserva y asignarla a la mesa seleccionada?',
      reassignText: '¿Desea mover esta reserva a otra mesa o agregar otra mesa a la reserva?',
      reassign: 'Mover',
      link: 'Conectar',
      cancel: '<base.cancel>',
      tableReservations: 'Reservas de mesa',
      close: '<base.close>',
      createReservation: 'Crear reserva',
      newWalkIn: 'Reserva rápida',
      indicateSeats: 'Indique el número de invitados',
      indicateFinish: 'Hora de finalización de la reserva',
      unknownUser: '<base.unknownUser>',
      showPast: 'Mostrar reservas finalizadas',
      hideReservations: 'Ocultar lista de reservas',
    },
    Profile: {
      title: 'Perfil del usuario',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.profile' },
      save: '<base.save>',
      logout: 'Cerrar sesión',
    },
    PeriodsSelector: {
      select: {
        today: 'Hoy',
        yesterday: 'Ayer',
        thisWeek: 'Esta semana',
        lastWeek: 'La semana pasada',
        thisMonth: 'Este mes',
        lastMonth: 'El mes pasado',
        thisYear: 'Este año',
        lastYear: 'El año pasado',
      },
      orChoosePeriod: 'O elija un período',
    },
    Reviews: {
      google: 'Reseñas de Google',
      facebook: 'Reseñas en Facebook',
      reservble: 'Reseñas de Reservble',
      seeAll: '<base.seeAll>',
      connectGoogle: 'Ingrese el ID del lugar en Google My Business para ver reseñas de Google',
      connectFacebook: 'Conecte Facebook Business Manager para ver reseñas de Facebook',
      connect: 'Conectar',
      noReviews: 'Aún no ha recibido reseñas aquí',
      expand: 'Expandir',
      collapse: 'Contraer',
      deleteReview: 'Eliminar reseña',
      deletingReview: 'Eliminando una reseña',
      confirmDeleteReview: '¿Está seguro que desea eliminar esta reseña? Esta acción no se puede deshacer.',
    },
    UserModal: {
      birthday: 'Fecha de nacimiento',
      gender: 'Género',
      familyStatus: 'Estado civil',
      comment: 'Comentario',
      save: '<base.save>',
      preferences: 'Preferencias',
      favorite: 'Platos favoritos',
      dontLike: 'No me gusta',
      cuisines: 'Cocinas favoritas',
      allergies: 'Alergias',
      statistics: 'Estadísticas',
      ourPlace: 'Nuestro establecimiento',
      allPlaces: 'Todos los establecimientos',
      visits: 'Número total de visitas',
      people: 'Número promedio de personas',
      averageBill: 'Factura promedio',
      reservationHistory: 'Historial de reservas',
      showAll: 'Mostrar todas',
    },
    RestaurantStatistics: {
      '@extends': 'types.reservation',
      regularClients: 'Clientes habituales',
      total: 'Total',
      regular: 'Habituales',
      reviews: 'Reseñas en <=service>',
      reviewsNumber: {
        1: 'reseña',
        2: 'reseñas',
        5: 'reseñas',
      },
      details: 'Detalles',

      onlineReservations: 'Reservas en línea',
      handReservations: 'Reservas manuales',
      newUsers: 'Nuevos clientes',
      chartTitle: 'Estadísticas del restaurante',
    },
    Payment: {
      title: 'Pago',
      pay: 'Recargar saldo',
      currentBalance: 'Saldo actual',
      balanceDetails:
        'Este es su saldo actual. A continuación se muestra el desglose de gastos para el período de facturación actual. Los cargos se deducirán del saldo al final del período de facturación actual',
      currentBillType: 'Tarifa actual',
      billingPeriod: 'Período de facturación',
      chatbot: 'Chatbot en Facebook/Instagram',
      menu: 'Menú',
      tariffPayment: 'Costo base de la tarifa',
      reservationsInTariff: 'Reservas en la tarifa',
      reservationsUsed: 'Reservas utilizadas',
      reservationsOverTariff: 'Pago por reservas adicionales',
      smsTotal: 'Pago por SMS',
      commission: 'Comisión del sistema de pago (<=commission>%)',
      total: 'Total',
      paymentHistory: 'Historial de pagos',
      invoice: 'Factura',
      payment: 'Pago',
      invoiceDetails: 'Detalles de la factura',
      invoicesHistory: 'Historial de facturas',
      invoiceBillType: 'Tipo de tarifa',
      makingPayment: 'Realizar un pago',
      selectAmount: 'Seleccione la cantidad',
      orSelect: 'O seleccione un período para el pago',
      INPROCESS: 'Pendiente',
      DONE: 'Pagado',
      DONE_PAYMENT: 'Recargado',
      months: {
        1: 'Un mes',
        2: '2 meses',
        3: '3 meses',
        6: '6 meses',
        9: '9 meses',
        12: '12 meses',
      },
      proceed: 'Continuar',
      errors: { '@extends': 'errors.validation' },
    },
    SuperAdmin: {
      menu: {
        organizations: 'Organizaciones',
        places: 'Establecimientos',
        countries: 'Países',
        cities: 'Ciudades',
        tags: 'Etiquetas',
        invoices: 'Facturas',
      },
      organizations: {
        addOrganization: 'Agregar organización',
        title: 'Organizaciones disponibles',
        addingOrganization: 'Añadiendo organización',
        editingOrganization: 'Editando organización',
        deletingOrganization: 'Eliminando organización',
        confirmDeleteOrganization: '¿Está seguro de que desea eliminar esta organización del sistema?',
        fields: {
          name: 'Nombre',
          created: 'Fecha de creación',
        },
        errors: { '@extends': 'errors.validation' },
      },
      places: {
        addRestaurant: 'Agregar restaurante',
        title: 'Restaurantes disponibles',
        activate: 'Activar',
        deactivate: 'Desactivar',
        addingRestaurant: 'Añadiendo restaurante',
        editingRestaurant: 'Editando restaurante',
        deletingRestaurant: 'Eliminando restaurante',
        confirmDeleteRestaurant:
          '¿Está seguro de que desea eliminar este restaurante del sistema? Esto eliminará permanentemente todos los datos asociados con él.',
        activatingRestaurant: 'Activando restaurante',
        deactivatingRestaurant: 'Desactivando restaurante',
        confirmActivateRestaurant: '¿Está seguro de que desea activar este restaurante?',
        confirmDeactivateRestaurant:
          '¿Está seguro de que desea desactivar este restaurante? No se mostrará en el sitio web del cliente y todas las cuentas asociadas con él serán bloqueadas temporalmente',
        fields: {
          name: 'Nombre',
          ownerName: 'Nombre del propietario',
          ownerLastName: 'Apellido del propietario',
          ownerPhone: 'Teléfono del propietario',
          active: 'Activo',
          billType: 'Tipo de tarifa',
          created: 'Fecha de creación',
          charityBill: 'Total de facturas',
          organization: 'Organización',
        },
        sort: {
          date: 'Por fecha de creación',
          name: 'Por nombre',
        },
        errors: { '@extends': 'errors.validation' },
      },
      countries: {
        addCountry: 'Agregar país',
        title: 'Países disponibles',
        addingCountry: 'Agregando país',
        editingCountry: 'Editando país',
        fields: {
          id: 'ID',
          name: "Nombre",
          lang: "Idioma",
        },
        lang: { '@extends': 'lang' },
        errors: { '@extends': 'errors.validation' },
      },
      cities: {
        addCity: 'Agregar ciudad',
        title: 'Ciudades disponibles',
        addingCity: 'Añadiendo ciudad',
        editingCity: 'Editando ciudad',
        fields: {
          id: 'ID',
          code: 'Nombre',
          country: "País",
          timezone: "Zona horaria",
        },
        errors: { '@extends': 'errors.validation' },
      },
      tags: {
        addTag: 'Agregar etiqueta',
        tags: {
          addTag: 'Agregar etiqueta',
          title: 'Etiquetas disponibles',
          addingTag: 'Añadiendo etiqueta',
          editingTag: 'Editando etiqueta',
          fields: {
            id: 'ID',
            name: 'Nombre',
            type: 'Tipo',
            search: 'Buscar',
          },
          errors: { '@extends': 'errors.validation' },
        },
        invoices: {
          title: 'Facturas nuevas',
          approve: 'Aprobar',
          approvingInvoices: 'Aprobando facturas',
          confirmApproveInvoices: '¿Está seguro de que desea aprobar todas las facturas seleccionadas?',
          editingInvoice: 'Editando factura',
          fields: {
            id: 'ID',
            place: 'Restaurante',
            date: 'Fecha',
            amount: 'Cantidad',
            adjustment: 'Ajuste',
            status: 'Estado',
            comment: 'Comentario',
            confirm: '<base.confirm>'
          },
          errors: { '@extends': 'errors.validation' },
        },
      },
    },
    NoAccess: {
      message: 'Su tarifa actual no incluye acceso a esta página. Para cambiar la tarifa, contacte al ',
      admin: 'administrador',
    },
    ReservationHistoryModal: {
      close: '<base.close>',
      title: 'Historial de cambios de reserva',
      date: 'Fecha',
      time: 'Hora',
      user: 'Usuario',
      action: 'Acción',
      guest: '<base.unknownUser>',
      new: 'Reserva creada',
      billRemoved: 'Видалено депозит',
      changed: 'Reserva modificada',
      status: {
        REQUESTED: 'El invitado creó una nueva reserva',
        ACCEPTED: 'Reserva confirmada',
        INPROGRESS: 'El invitado llegó al restaurante',
        REJECTED: 'Reserva rechazada por el administrador',
        FINISHED: 'Reserva marcada como completada',
        SKIPPED: 'El invitado no asistió',
        CANCELED: 'Reserva cancelada por el invitado',
      },
      fields: { '@extends': 'types.reservation' },
    },
    Dishes: {
      title: 'Menú',
      addCategory: 'Agregar categoría',
      edit: '<base.edit>',
      delete: '<base.delete>',
      addDish: 'Agregar plato',
      uploadPhoto: 'Subir foto',
      addingCategory: 'Añadiendo categoría',
      editingCategory: 'Editando categoría',
      addingDish: 'Añadiendo plato',
      editingDish: 'Editando plato',
      deletingCategory: 'Eliminando categoría',
      confirmDeleteCategory: '¿Está seguro de que desea eliminar esta categoría del menú?',
      deletingDish: 'Eliminando plato',
      confirmDeleteDish: '¿Está seguro de que desea eliminar este plato? No estará disponible en el futuro',
      uah: '<currencies.uah>',
      category: {
        '@extends': 'types.dishCategory',
      },
      dish: {
        '@extends': 'types.dish',
      },
      errors: {
        '@extends': 'errors.validation',
      },
    },
    ConflictingReservationsModal: {
      title: 'Hay otra reserva para esta mesa',
      cancel: '<base.cancel>',
      confirm: '<base.confirm>',
      description:
        '¿Está seguro de que desea asignar la reserva a esta mesa y en la fecha y hora indicadas? Este espacio ya está ocupado por las siguientes reservas:',
    },
    OverduePaymentPopup: {
      message: 'El establecimiento tiene una factura impaga. Recargue su saldo en la página de pagos',
    },
    Hours: {
      notWorkingDays: 'Días no laborables',
      workingDays: 'Días laborables',
      selectDate: 'Seleccione una fecha',
      addDate: 'Añadir fecha',
      dateList: 'Lista de días no laborables',
      dateOpenList: 'Lista de días laborables',
    },
    DepositManagement: {
      switch: 'Depósito',
      total: 'Total',
      quantity: 'Cant',
      price: 'Precio',
      uah: '<currencies.uah>',
    },
    PaymentStatistics: {
      depositsTab: 'Depósitos',
      certificatesTab: 'Certificados',
      deposits: {
        name: 'Nombre',
        phone: 'Número de teléfono',
        hall: 'Sala',
        spot: 'Mesa',
        billId: 'ID de pago',
        amount: 'Cantidad',
        total: 'Total',
      },
      certificates: {
        '@extends': 'types.certificate',
        firstName: 'Nombre',
        phone: 'Número de teléfono',
        id: 'Número único',
        price: '<types.certificate.price>',
        name: 'Nombre del certificado',
        date: 'Fecha de compra',
        total: 'Total',
      },
      uah: '<currencies.uah>',
    },
    MenuModal: {
      title: 'Menú',
      search: 'Introduce el nombre del plato',
      uah: '<currencies.uah>',
      cancel: '<base.cancel>',
      save: 'Añadir a la reserva',
      preview: 'Preestreno',
      total: 'Total',
      prepayment: 'Monto del prepago',
    },
    PreviewMenuModal: {
      title: 'Los platos se añaden al pedido',
      total: 'Total',
      uah: '<currencies.uah>',
      edit: '<base.edit>',
    },
    DishCard: {
      uah: '<currencies.uah>',
      qty: 'Cant',
    },
    DishCategoriesMenu: {
      edit: '<base.edit>',
      delete: '<base.delete>'
    },
    CertificateSettings: {
      addCertificate: 'Crear certificado',
      addingCertificate: 'Añadiendo certificado',
      editingCertificate: 'Editando certificado',
      deletingCertificate: 'Eliminando certificado',
      confirmDeleteCertificate: '¿Está seguro de que desea eliminar este certificado? No estará disponible en el futuro',
      uploadPhoto: 'Subir foto',
      fields: { '@extends': 'types.certificate', },
      errors: { '@extends': 'errors.validation' },
    },
    CertificateSettingsCard: {
      '@extends': 'types.certificate',
      active: 'Activo',
      inactive: 'Inactivo',
      days: 'días',
      uah: '<currencies.uah>',
      edit: '<base.edit>',
      delete: '<base.delete>',
    },
    Certificates: {
      title: 'Certificados',
      addCertificate: 'Crear certificado',
      addNewCertificate: 'Añadir nuevo certificado',
      certificateDetails: 'Detalles del certificado',
      cancel: '<base.cancel>',
      save: '<base.save>',
      delete: '<base.delete>',
      fields: { '@extends': 'types.certificate' },
      uah: '<currencies.uah>',
      days: 'días',
      visits: 'visitas',
      activate: 'Activar',
      sendAgain: '<base.sendAgain>',
      sms: 'СМС повідомлення',
      smsSent: 'Enviado',
      smsNotSent: 'No enviado',
      deletingCertificate: 'Eliminando certificado',
      confirmDeleteCertificate: '¿Está seguro de que desea eliminar este certificado? Esta acción no se puede deshacer',
      paid: 'Pagado en efectivo',
      messages: { '@extends': 'actions.certificates' },
      errors: { '@extends': 'errors.validation' },
      filter: {
        certificates: 'Certificados',
        subscriptions: 'Suscripciones',
      },
    },
  },
  actions: {
    place: {},
    hall: {
      saved: 'Cambios guardados',
      hallAdded: 'Salón añadido exitosamente',
      spotDeleted: 'Mesa eliminada',
    },
    settings: {
      saved: 'Configuraciones guardadas',
      menuConnected: 'El menú se ha cargado correctamente',
    },
    clients: {
      added: 'Cliente añadido',
      exist: 'Usuario añadido bajo el nombre <=displayedName>',
    },
    administrations: {
      added: 'Usuario añadido',
      exist: 'Usuario añadido bajo el nombre <=displayedName>',
      edited: 'Permisos del usuario editados',
      deleted: 'Permisos del usuario eliminados',
    },
    profile: {
      saved: 'Cambios guardados',
      passwordChanged: 'Contraseña cambiada exitosamente',
      oldPasswordIsInvalid: 'La contraseña antigua es incorrecta',
    },
    reservation: {
      success: 'Reserva actualizada exitosamente',
      alreadyBookedTable: 'Ya existe una reserva para esta mesa en este horario',
      busySlotForReservation: 'El administrador prohíbe crear reservas para el mismo tiempo',
    },
    certificates: {
      added: 'Certificado creado exitosamente',
      saved: 'Cambios guardados',
      smsResent: 'SMS enviado de nuevo',
      notFound: 'No se encontró el certificado con este número',
    },
  },
  service: {
    jwt: {
      invalidToken: 'Token de acceso inválido',
      accessExpired: 'El token de acceso ha expirado',
      signinInProcess: 'Iniciando sesión...',
      welcomeMessage: 'Bienvenido a RESERVBLE Resto UI',
      loginWithTokenFailed: 'Inicio de sesión con token fallido',
    },
  },
};
