import React, { useState } from 'react';
import Card from '@components/Card/Card';
import Box from '@mui/material/Box';
import mova from 'mova';
import Button from '@components/Button/Button';
import Typography from '@mui/material/Typography';
import { MenuItem, useMediaQuery, useTheme } from '@mui/material';
import Icon from '@components/Icon/Icon';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FormikTextInput from '@form/FormikTextInput';
import Loader from '@components/Loader/Loader';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { useDispatch, useSelector } from 'react-redux';
import { toastrActions } from 'state/ducks/toastr';
import SyrveIntegrations from './components/SyrveIntegrations';
import PortmoneIntegrations from "./components/PortmoneIntegrations";
import PosterIntegrations from "./components/PosterIntegrations";
import FormikSelect from "../../../../form/FormikSelect";
import { locales } from "../../../../../utils/i18nUtils";

const t = mova.ns('components.Integrations');
const tSettings = mova.ns('components.Settings');

const telegramSchema = Yup.object().shape({
  telegramChatId: Yup.number(() => t('errors.number')).required(() => t('errors.required')),
});

const googleSchema = Yup.object().shape({
  googleApiKey: Yup.string().required(() => t('errors.required')),
  googleApiLang: Yup.string(),
});

const facebookSchema = Yup.object().shape({
  facebookPageAccessKey: Yup.string().required(() => t('errors.required')),
});

const googleLangMap = (val) => ({
    ua: 'uk'
  })[val] || val;

const Integrations = () => {
  const dispatch = useDispatch();
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const isPlaceLoading = useSelector(placeSelectors.getPlaceLoading());
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();

  const [telegramConnectionFailed, setTelegramConnectionFailed] = useState(false);
  const telegramConnected = activePlace.connections?.telegramChatId?.length;

  const [googleApiFailed, setGoogleApiFailed] = useState(false);
  const googleConnected = activePlace.connections?.googleApiKey?.length;
  const facebookConnected = false;

  const connectTelegram = async data => {
    const result = await dispatch(
      placeThunks.updatePlaceConnections({
        id: activePlace.id,
        connections: {
          telegramChatId: data.telegramChatId,
        },
      }),
    );

    if (result?.payload?.connections?.telegramChatId?.ok === false) {
      setTelegramConnectionFailed(true);
    } else {
      setTelegramConnectionFailed(false);
      await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
    }
  };

  const connectGoogle = async data => {
    const result = await dispatch(
      placeThunks.updatePlaceConnections({
        id: activePlace.id,
        connections: {
          googleApiKey: data.googleApiKey,
          googleApiLang: data.googleApiLang,
        },
      }),
    );

    if (result?.payload?.connections?.googleApiKey !== data.googleApiKey) {
      setGoogleApiFailed(true);
    } else {
      setGoogleApiFailed(false);
      await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
    }
  };

  const connectFacebook = () => {
    //  toDO: @bkitaec implement logic
  };

  const langOptions = locales
    .map(locale => <MenuItem key={locale} value={googleLangMap(locale)}>{t(`lang.${locale}`)}</MenuItem>);


  return (
    <Box>
      {isPlaceLoading && <Loader fullScreen />}
      <Card>
        <Formik
          initialValues={{
            telegramChatId: telegramConnected ? activePlace.connections?.telegramChatId : '',
          }}
          onSubmit={connectTelegram}
          validationSchema={telegramSchema}
        >
          <Form>
            <Typography variant='h3' sx={{ mb: 2 }}>
              {t('telegramService')}
            </Typography>
            <Box
              display='flex'
              alignItems={mobileView ? 'flex-start' : 'center'}
              justifyContent='space-between'
              flexDirection={mobileView ? 'column' : 'row'}
            >
              <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
                {t('telegramKey')}
              </Typography>
              <FormikTextInput sx={{ flexGrow: 3 }} name='telegramChatId' fullWidth placeholder={t('telegramKey')} />
            </Box>
            {telegramConnected && (
              <Box display='flex' alignItems='center' mt={1}>
                <Icon type='done' sx={{ mr: 1 }} />
                <Typography variant='h4' color='success.main'>
                  {t('connected')}
                </Typography>
              </Box>
            )}
            {telegramConnectionFailed && (
              <Box display='flex' alignItems='center' mt={1}>
                <Icon type='warning' sx={{ mr: 1 }} color={theme.palette.secondary.main} />
                <Typography variant='h4'>{t('telegramConnectionFailed')}</Typography>
              </Box>
            )}
            <Box display='flex' justifyContent='center' mt={2}>
              <Button type='submit'>{t('connect')}</Button>
            </Box>
          </Form>
        </Formik>
      </Card>
      <Card sx={{ mt: 4 }}>
        <Formik
          initialValues={{
            googleApiKey: googleConnected ? activePlace.connections?.googleApiKey : '',
            googleApiLang: activePlace.connections?.googleApiLang || 'ua',
          }}
          onSubmit={connectGoogle}
          validationSchema={googleSchema}
        >
          <Form>
            <Typography variant='h3' sx={{ mb: 2 }}>
              {t('googleService')}
            </Typography>
            <Box
              display='flex'
              alignItems={mobileView ? 'flex-start' : 'center'}
              justifyContent='space-between'
              flexDirection={mobileView ? 'column' : 'row'}
            >
              <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
                {t('googleKey')}
              </Typography>
              <FormikTextInput sx={{ flexGrow: 3 }} name='googleApiKey' fullWidth placeholder={t('googleKey')} />
            </Box>
            {googleConnected && (
              <Box display='flex' alignItems='center' mt={1}>
                <Icon type='done' sx={{ mr: 1 }} />
                <Typography variant='h4' color='success.main'>
                  {t('connected')}
                </Typography>
              </Box>
            )}
            <Box display='flex' alignItems='center' mt={1} gap={2}>
              <Typography>{t('commentLanguage')}</Typography>
              <FormikSelect name='googleApiLang' fullWidth placeholder={t('commentLanguage')} sx={{ minWidth: 200 }}>
                {langOptions}
              </FormikSelect>
            </Box>
            {googleApiFailed && (
              <Box display='flex' alignItems='center' mt={1}>
                <Icon type='warning' sx={{ mr: 1 }} color={theme.palette.secondary.main} />
                <Typography variant='h4'>{t('googleApiFailed')}</Typography>
              </Box>
            )}
            <Box display='flex' justifyContent='center' mt={2}>
              <Button type='submit'>{t('connect')}</Button>
            </Box>
          </Form>
        </Formik>
      </Card>
      {facebookConnected && (
        <Card sx={{ mt: 4 }}>
          <Formik
            initialValues={{
              facebookPageAccessKey: '',
            }}
            onSubmit={connectFacebook}
            validationSchema={facebookSchema}
          >
            <Form>
              <Typography variant='h3' sx={{ mb: 2 }}>
                {t('facebookService')}
              </Typography>
              <Box
                display='flex'
                alignItems={mobileView ? 'flex-start' : 'center'}
                justifyContent='space-between'
                flexDirection={mobileView ? 'column' : 'row'}
              >
                <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
                  {t('facebookKey')}
                </Typography>
                <FormikTextInput
                  sx={{ flexGrow: 3 }}
                  name='facebookPageAccessKey'
                  fullWidth
                  placeholder={t('facebookKey')}
                />
              </Box>
              {facebookConnected && (
                <Box display='flex' alignItems='center' mt={1}>
                  <Icon type='done' sx={{ mr: 1 }} />
                  <Typography variant='h4' color='success.main'>
                    {t('connected')}
                  </Typography>
                </Box>
              )}
              <Box display='flex' justifyContent='center' mt={2}>
                <Button type='submit'>{t('connect')}</Button>
              </Box>
            </Form>
          </Formik>
        </Card>
      )}
      <SyrveIntegrations />
      <PortmoneIntegrations />
      <PosterIntegrations />
    </Box>
  );
};

export default Integrations;
